import Vue from "vue";
import VueRouter from "vue-router";
import TransportCompanyRegisterView from "../views/TransportCompanyRegisterView";
import TransportCompanyLoginView from "../views/TransportCompanyLoginView";
import TransportCompanyResetPasswordView from "../views/TransportCompanyResetPasswordView";
import TransportCompanyNewPasswordView from "../views/TransportCompanyNewPasswordView";
import OnboardingIdentity from "@/views/OnboardingIdentity";
import OnboardingAddress from "@/views/OnboardingAddress";
import OnboardingContact from "@/views/OnboardingContact";
import OnboardingVerification from "@/views/OnboardingVerification";
import CompanyStaffNewPassword from "@/views/CompanyStaffNewPassword";
import {isTokenValid,getUserData} from "../services/api/AuthApiService"
import {getTransportByWebsite} from "@/services/api/APIService";
import OnboardingAccountDetails from "../views/OnboardingAccountDetails";
import store from "@/store";






Vue.use(VueRouter);

const routes = [
  { path: '*', redirect: '/404' },


  {
    path: "",
    name: "NewBooking",
    component: () => import("@/views/MakeBooking.vue")
  },
  {
    path: "/one-way-trips",
    name: "OneWayTrips",
    component: ()=> import("@/views/OneWayBooking/SelectBus")
  },
  {
    path: "/one-way-select-seats",
    name: "OneWaySelectSeats",
    component : () => import("@/views/OneWayBooking/SelectSeats")
  },
  {
    path: "/one-way-passengers-details",
    name: "OneWayInputDetails",
    component: ()=> import("@/views/OneWayBooking/InputDetails")
  },
  {
    path: "/one-way-check-out",
    name: "OneWayCheckout",
    component: () => import("@/views/OneWayBooking/CheckOut")
  },
  {
    path: "/register",
    name: "TransportCompanyRegister",
    component: TransportCompanyRegisterView,
  },
  {
    path: "/login",
    name: "TransportCompanyLogin",
    component: TransportCompanyLoginView,
  },
  {
    path: "/reset-password",
    name: "TransportCompanyResetPassword",
    component: TransportCompanyResetPasswordView,
  },
  {
    path: "/new-password",
    name: "TransportCompanyNewPassword",
    component: TransportCompanyNewPasswordView,
  },
  {
    path: "/onboarding-identity",
    name: "OnboardingIdentity",
    component: OnboardingIdentity,
  },
  {
    path: "/onboarding-address",
    name: "OnboardingAddress",
    component: OnboardingAddress,
  },
  {
    path: "/onboarding-contact",
    name: "OnboardingContact",
    component: OnboardingContact,
  },
  {
    path: "/onboarding-verification",
    name: "OnboardingVerification",
    component: OnboardingVerification,
  },
  {
    path: "/onboarding-account-details",
    name: "OnboardingAccountDetails",
    component: OnboardingAccountDetails
  },
  {
    path: "/company-staff/new-password",
    name: "CompanyStaffNewPassword",
    component: CompanyStaffNewPassword,
  },
  {
    path: "/awaiting-verification",
    name: "AwaitingVerification",
    component: () => import("@/components/transportCompany/CompanyAwaitVerification.vue")
  },
  {
    path: "/404",
    name: "errorpage",
    component: () => import("@/components/reuseables/ErrorPage.vue")
  },
  {
    path: "/company-staff/dashboard",
    redirect: {
      name: "CompanyStaffDashboard"
    },
    name: "StaffDashboardContainer",
    component: () => import("@/components/terminalManager/TerminalManagerContainer.vue"),
    children: [{
        path: "",
        name: "CompanyStaffDashboard",
        component: () =>
          import("@/components/terminalManager/CompanyStaffDashboard.vue"),
        meta: {
          userType: "companyStaff"
        }
      },
      {
        path: "terminal",
        name: "TerminalManagerTerminals",
        component: () =>
          import(
            "@/components/terminalManager/terminal/ViewTerminalDashboardRoute.vue"
          ),
        meta: {
          userType: "companyStaff"
        }
      },
      {
        path: "booking-admin",
        name: "TerminalManagerBookingAdmins",
        component: () =>
          import(
            "@/components/transportCompany/booking/ViewBookingRoute.vue"
          ),
        meta: {
          userType: "companyStaff"
        }
      },
      {
        path: "manager",
        name: "TerminalManagerManagers",
        component: () =>
          import(
            "@/components/terminalManager/manager/ViewManagerDashboardRoute.vue"
          ),
        meta: {
          userType: "companyStaff"
        }
      },
      {
        path: "fleet",
        name: "TerminalManagerFleets",
        component: () =>
          import(
            "@/components/terminalManager/fleet/ViewFleetDashboardRoute.vue"
          ),
        meta: {
          userType: "companyStaff"
        }
      },
      {
        path: "pilot",
        name: "TerminalManagerPilots",
        component: () =>
          import(
            "@/components/terminalManager/pilot/ViewPilotDashboardRoute.vue"
          ),
        meta: {
          userType: "companyStaff"
        }
      },
      {
        path: "trip",
        name: "TerminalManagerTrips",
        component: () =>
          import(
            "@/components/terminalManager/trip/ViewTripDashboardRoute.vue"
          ),
        meta: {
          userType: "companyStaff"
        }
      },
      {
        path: "booking",
        name: "TerminalManagerBookings",
        component: () =>
          import("@/components/transportCompany/booking/ViewBookingRoute.vue"),
        meta: {
          userType: "companyStaff"
        }
      },
      {
        path: "manifest",
        name: "Manifest",
        redirect:{
          name: "AllManifest"
        },
        component: () => import("@/components/transportCompany/manifest/ViewManifestRoute"),
        meta: {
          userType: "companyStaff"
        },
        children:[
          {
            path : "",
            name: "AllManifestCompanyStaff",
            component : () => import("@/components/transportCompany/manifest/AllManifest"),
            meta: {
              userType: "companyStaff"
            }
          },
          {
            path : "/company-staff/dashboard/manifest/:manifestId",
            name: "SingleManifestCompanyStaff",
            component : () => import("@/components/transportCompany/manifest/SingleManifest"),
            meta: {
              userType: "companyStaff"
            }
          }
        ]
      },
      {
        path: "routes",
        name: "TerminalManagerRoutes",
        component: () =>
          import("@/components/terminalManager/route/ViewTerminalManagerRoute.vue"),
        meta: {
          userType: "companyStaff"
        }
      },
    ],
  },
  {
    path: "/transport-company/dashboard",
    name: "TransportCompanyDashboard",
    redirect: {
      name: "MainDashboard"
    },
    component: () => import("@/views/TransportCompanyDashboard.vue"),
    children: [{
      path: "",
      name: "MainDashboard",
      component: () =>
          import("@/components/transportCompany/MainDashboard.vue"),
      meta: {
        userType: "transportCompany"
      }
    },
      {
        path: "/transport-company/manifest",
        name: "Manifest",
        redirect: {
          name: "AllManifest"
        },
        meta: {
          userType: "transportCompany"
        },
        component: () => import("@/components/transportCompany/manifest/ViewManifestRoute"),
        children: [
          {
            path: "",
            name: "AllManifest",
            component: () => import("@/components/transportCompany/manifest/AllManifest"),
            meta: {
              userType: "transportCompany"
            }
          },
          {
            path: "/transport-company/manifest/:manifestId",
            name: "SingleManifest",
            component: () => import("@/components/transportCompany/manifest/SingleManifest"),
            meta: {
              userType: "transportCompany"
            }
          }
        ]
      },
      {
        path: "/transport-company/dashboard/terminal",
        name: "Terminals",
        component: () =>
            import(
                "@/components/transportCompany/terminal/ViewTerminalDashboardRoute.vue"
                ),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/manager",
        name: "Managers",
        component: () =>
            import("@/components/transportCompany/ViewManagerDashboardRoute.vue"),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/booking-admin",
        name: "BookingAdmin",
        component: () =>
            import(
                "@/components/transportCompany/bookingAdmin/ViewBookingAdminRoute.vue"
                ),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/leasing",
        name: "LeasingDashboard",
        component: () => import("@/components/transportCompany/leasing/LeasingDashboard"),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/create-criteria",
        name: "CreateHiringCriteria",
        component: () =>
            import("@/components/transportCompany/leasing/CreateHiringCriteria.vue"),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/edit-hiring",
        name: "EditHiringCriteria",
        component: () => import("@/components/transportCompany/leasing/EditHiringCriteria.vue"),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/all-hiring",
        name: "AllHiringCriteria",
        component: () => import("@/components/transportCompany/leasing/AllHiringCriteria.vue"),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/pilots",
        name: "Pilots",
        component: () =>
            import("@/components/transportCompany/pilot/ViewPilotDashboardRoute.vue"),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/fleet",
        name: "Fleets",
        component: () =>
            import(
                "@/components/transportCompany/fleet/ViewFleetDashboardRoute.vue"
                ),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/route",
        name: "Routes",
        component: () =>
            import("@/components/transportCompany/route/ViewRouteDashboardRoute.vue"),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/trip",
        name: "Trips",
        component: () =>
            import(
                "@/components/transportCompany/trip/ViewTripDashboardRoute.vue"
                ),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/coupon",
        name: "CouponDashboard",
        component: () => import("@/components/transportCompany/discount/CouponDashboard.vue"),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/dashboard/booking",
        name: "Booking",
        component: () =>
            import("@/components/transportCompany/booking/ViewBookingRoute.vue"),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/settings",
        name: "Settings",
        component: () => import("@/components/reuseables/AccountSetting.vue"),
        meta: {
          userType: "transportCompany"
        }
      },
      {
        path: "/transport-company/wallet",
        name: "WalletDashboard",
        component: () => import("@/views/wallet/WalletDashboard"),
        meta: {
          userType: "transportCompany"
        }
      },

      {
        path: "/transport-company/update-account",
        name: "UpdateAccount",
        component: () => import("@/views/wallet/UpdateAccount"),
        meta: {
          userType: "transportCompany"
        }
      },
    ],

  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: "smooth"
    };
  },
});
router.beforeEach((to, from, next) => {
  let token = to.query.access_token
  if (token){
    getUserData(token)
    router.replace({query:null}).then()
    token = null
    let userData = JSON.parse(sessionStorage.getItem('userData'))

    router.push({name: getRoute(userData)}).then(() => {})

  }

  let userData = JSON.parse(sessionStorage.getItem('userData'))
  if (!userData){
    store.dispatch("companyProfile/setLoading", true).then()
    getTransportByWebsite(window.location.hostname).then(res=>{
      store.dispatch("companyProfile/setCompanyDataForTraveller",res.data).then(()=>{
        store.dispatch("companyProfile/setLoading", false).then()
      })
    })
  }
  if (to.meta.userType) {

    if (!userData) {
      next({
        name: 'TransportCompanyLogin'
      })
    }else {
      isTokenValid(userData.exp)
     // if (to.meta.userType === "companyStaff" && userData.realm_access.roles.includes("TRANSPORT_COMPANY")) {
     //    next({
     //      name: "MainDashboard"
     //    })
     //  } else if (to.meta.userType === "transportCompany" && userData.realm_access.roles.includes("TRANSPORT_COMPANY_MANAGER")
     //     || userData.realm_access.roles.includes("TRANSPORT_COMPANY_BOOKING_ADMIN")) {
     //    next({
     //      name: "CompanyStaffDashboard"
     //    })
     //  }
    }
  }
  next();
})

// router.beforeEach((to, from, next) => {
//   let token = to.query.access_token
//   if (token){
//     getUserData(token)
//     router.replace({query:null}).then()
//     token = null
//     router.push({name: 'MainDashboard'}).then(() => {})
//
//   }
//
//   let userData = JSON.parse(sessionStorage.getItem('userData'))
//   if (!userData){
//     store.dispatch("companyProfile/setLoading", true).then()
//     getTransportByWebsite(window.location.hostname).then(res=>{
//       store.dispatch("companyProfile/setCompanyDataForTraveller",res.data).then(()=>{
//         store.dispatch("companyProfile/setLoading", false).then()
//       })
//     })
//   }
//   if (to.meta.userType) {
//
//     if (!userData) {
//       next({
//         name: 'TransportCompanyLogin'
//       })
//     } else {
      // isTokenValid(userData.exp)
      //
      // if (to.meta.userType === "companyStaff" && userData.realm_access.roles.includes("TRANSPORT_COMPANY")) {
      //   if (to.name !== "MainDashboard") {
      //     next({name: "MainDashboard"});
      //   } else {
      //     next();
      //   }
      // } else if (to.meta.userType === "transportCompany" && (userData.realm_access.roles.includes("TRANSPORT_COMPANY_MANAGER")
      //     || userData.realm_access.roles.includes("TRANSPORT_COMPANY_BOOKING_ADMIN"))) {
      //   if (to.name !== "CompanyStaffDashboard") {
      //     next({name: "CompanyStaffDashboard"});
      //   } else {
      //     next();
      //   }
      // } else {
      //   next();
      // }
//     }
//   }
// })

export default router;

function getRoute(userData){
  if (userData.realm_access.roles.includes("TRANSPORT_COMPANY")){
    return "MainDashboard";
  }
  else {
    return "CompanyStaffDashboard"
  }
}