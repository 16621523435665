<template>
  <v-dialog
    persistent
    v-model="dialog"
    overlay-color="black"
    overlay-opacity="0.5"
  >
    <div class="tw-flex tw-w-full tw-flex-col">
      <div
        style=" width: 30px; height: 30px; border-radius: 50%; z-index: 1"
        class="tw-flex tw-justify-center  tw-items-center tw-self-end  tw-cursor-pointer"
        @click="$emit('close')"
      >
        <vue-feather type="x" style="color: white; " />
      </div>
      <div v-if="section === 'main'"
        class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
        style="min-height: 384px"
      >
        <icons :name="icon" :color="color" />
        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">
          {{ title }}&nbsp;<span v-if="userName">{{ userName }}</span>
        </p>
        <label
          class="desc "
          v-if="description"
          :class="!additionalText ? 'tw-pb-5' : ''"
          >{{ description }}</label
        >
        <label class="desc tw-pb-5 tw-mt-5" v-if="additionalText" :style="{color:color}" >{{
          additionalText
        }}</label>
        <a
          v-if="bottomText && downloadUrl"
          :href="downloadUrl"
          target="_blank"
          class="bottomtext tw-mt-5"
        >
          {{ bottomText }}
        </a>

        <router-link
          class="bottomtext tw-mt-5"
          :to="{ name: link }"
          v-else-if="bottomText"
        >
          {{ bottomText }}
        </router-link>
      </div>
      <div class=" tw-flex card  tw-justify-center tw-items-center tw-flex-col tw-p-8"
           style="min-height: 340px" v-if="section === 'cancelling'">
        <p class="cancel-title tw-justify-start tw-flex tw-items-start tw-w-full tw-pb-3"
           :style="{color:color}">Cancel Trip</p>
         <v-textarea :color="color" rows="6" outlined hide-details
                     label="State reason for cancelling"
                     class="tw-w-full" style="font-family: Inter, sans-serif!important;" v-model="reason">
         </v-textarea>

        <div class="tw-flex tw-w-full tw-justify-around tw-items-center action-btn-div">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{
              color: color,
              border: '1px solid' + color,}">
            Close
          </v-btn>
          <v-btn
              text
              class="second-btn"
              @click="handleCancelTrip"
              :style="{ background: color, color: 'white' }"
              :loading="loading">
            Cancel Trip
          </v-btn>
        </div>
      </div>

      <div v-if="section === 'print-ticket'"
           class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px"
      >
        <icons :name="icon" :color="color" />
        <p class="title tw-py-5 tw-mb-0 text-capitalize" :style="{color:color}">
          {{ title }}&nbsp;
        </p>
        <img src="../../assets/api-loading.gif" alt="loading" class="api-loading" v-if="description === 'please wait'" />
        <label
            class="desc text-capitalize"
            v-if="description"
            :class="!additionalText ? 'tw-pb-5' : ''"
        >{{ description }}</label
        >
        <div class="tw-flex tw-w-full tw-justify-around tw-items-center action-btn-div tw-pt-10" v-if="description !== 'please wait'">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{
              color: color,
              border: '1px solid' + color}">
            Close
          </v-btn>
          <v-btn
              text
              class="second-btn"
              @click="$emit('printTicket')"
              :style="{ background: color, color: 'white' }"
              :loading="loading">
            Print Ticket
          </v-btn>
        </div>

      </div>

      <div v-if="section === 'booking-completed'"
           class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px"
      >
        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">Booking successful!</p>
        <Icons name="thumbs-up" :color="color"/>
        <label class="desc tw-pb-5 tw-mt-5"  :style="{color:color}" >A booking confirmation have been sent to your email</label>
      </div>

      <loader v-if="loading" :loading="loading"/>
      <div v-if="section === 'add-hiring-route'" class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 484px; min-width: 512px">
        <p class="header" :style="{color: color}">Create Leasing Route</p>
       <div class="tw-mb-4 tw-w-full"> <label class="tw-flex ">
          <icons name="location-icon" class="tw-mr-3" :color="color"/>
          Departure City
        </label>
       </div>

        <v-combobox solo hide-details :items="citiesData" :item-text="getItemText" v-model="departureCity" class="tw-w-full">
          <template #append>
            <icons name="arrow-down" :color="color" />
          </template>
        </v-combobox>

        <div class="tw-mb-4 tw-w-full">
        <label class="tw-flex">
          <icons name="destination-icon" class="tw-mr-3" :color="color"/>
          Destination City
        </label>
        </div>
        <v-combobox solo hide-details :items="citiesData" :item-text="getItemText" v-model="destinationCity" class="tw-w-full">
          <template #append>
            <icons name="arrow-down" />
          </template>
        </v-combobox>

        <div class="tw-flex">
          <NewBaseButton :button-text="'Cancel'" :is-outlined="true" outlined="outlined" class="tw-mr-7" :color="color"/>
          <NewBaseButton :button-text="'Add Route'" @click="createNewRoute" :loading="addRouteLoading" :color="color"/>
        </div>

      </div>
     <div v-if="section === 'add-discount'" class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
          style="min-height: 484px; min-width: 512px">
       <p class="header" :style="{color: color}">Add Discount</p>

       <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4">
         <label class="desc  text-capitalize" :style="{color:color}">
           Name
         </label>
         <v-text-field solo hide-details
                       placeholder="Title e.g (Detty December)"
                       class="tw-w-full" v-model="discountDetail.name"></v-text-field>
       </div>
       <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4">
         <label class="desc text-capitalize" :style="{color:color}">Unit</label>
         <v-text-field solo hide-details type="number"
                       placeholder="Number to deploy e.g (3000)"
                       class="tw-w-full" v-model="discountDetail.unit"
         ></v-text-field>
       </div>
       <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-8">
         <label class="desc  text-capitalize" :style="{color:color}">Type</label>
         <v-radio-group row v-model="discountDetail.type">
           <v-radio value="FIXED" label="Fixed" :color="color"></v-radio>
           <v-radio value="PERCENTAGE" label="Percentage" :color="color"></v-radio>
         </v-radio-group>
       </div>
       <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4 tw-mb-8">
         <label class="desc text-capitalize" :style="{color:color}">Unit Amount</label>
         <v-text-field solo hide-details
                       type="number" placeholder="50"
                       class="tw-w-full" v-model="discountDetail.unitAmount"
                       prepend-inner-icon="₦" :color="color">

         </v-text-field>
       </div>
       <div class="tw-flex tw-justify-end tw-w-full tw-flex-col-reverse lg:tw-flex-row">
         <NewBaseButton :button-text="'Cancel'" :is-outlined="true" outlined="outlined"
                        @click="clearDiscountDetail" class="lg:tw-mr-7 tw-mt-5 lg:tw-mt-0" :color="color"
                        :width="$vuetify.breakpoint.mdAndUp ? 174 : '100%'"/>
         <NewBaseButton :button-text="'Add'" @click="sendDiscountDetail"  :color="color"/>
       </div>
     </div>
      <div v-if="section === 'create-voucher'" class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 484px" :style="{minWidth: $vuetify.breakpoint.mdAndUp ?'512px':'100%'}">
        <p class="header" :style="{color: color}">Create Voucher</p>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4">
          <label class="desc  text-capitalize" :style="{color:color}">
            Name
          </label>
          <v-text-field solo hide-details
                        placeholder="Title e.g (Detty December)"
                        class="tw-w-full" v-model="voucherDetail.name"></v-text-field>
        </div>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4">
          <label class="desc  text-capitalize" :style="{color:color}">
            Type
          </label>
          <v-select solo hide-details
                    placeholder=""
                    class="tw-w-full" v-model="discountDetail.type"
                    :items="voucherTypes"></v-select>
        </div>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4 tw-mb-8">
          <label class="desc text-capitalize" :style="{color:color}">Amount</label>
          <v-text-field solo hide-details
                        type="number" placeholder="50"
                        class="tw-w-full" v-model="voucherDetail.amount"
                        prepend-inner-icon="₦" :color="color">

          </v-text-field>
        </div>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4 tw-mb-8">
          <label class="desc text-capitalize" :style="{color:color}">Unit</label>
          <v-text-field solo hide-details
                        type="number" placeholder="100"
                        class="tw-w-full" v-model="voucherDetail.amount"
                         :color="color">

          </v-text-field>
        </div>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4 tw-mb-8">
          <label class="desc text-capitalize" :style="{color:color}">Short code (Optional)</label>
          <v-text-field solo hide-details
                        type="text" placeholder="NPOD & DON BABA"
                        class="tw-w-full" v-model="voucherDetail.schortCode"
                        :color="color">

          </v-text-field>
        </div>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4 tw-mb-8">
          <label class="desc text-capitalize" :style="{color:color}">Expiration Date</label>
            <v-menu v-model="voucherDateMenu" :close-on-content-click="true" :nudge-right="40"
                    transition="scale-transition" offset-y  class="tw-w-full">
              <template v-slot:activator="{ on }" class="tw-w-full">
                <ValidationProvider name="Date" rules="" v-slot="{ classes, errors }" class="tw-w-full">
                  <div class="" :class="classes">
                    <v-text-field v-model="voucherDetail.expirationDate"  v-on="on"
                                  placeholder="2022-09-9" solo hide-details readonly>
                      <template #prepend-inner>
                        <v-icon class="">
                          mdi-calendar-month-outline
                        </v-icon>
                      </template></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </template>

              <v-date-picker  locale="en-in" v-model="voucherDetail.expirationDate" no-title
                              @input="voucherMenu = false" :min="minDate">
              </v-date-picker>
            </v-menu>
        </div>
        <div class="tw-flex tw-justify-end tw-w-full tw-flex-col-reverse lg:tw-flex-row">
          <NewBaseButton :button-text="'Cancel'" :is-outlined="true" outlined="outlined"
                         class="lg:tw-mr-7 tw-mt-5 lg:tw-mt-0" :color="color"  @click="clearVoucherDetail"
          :width="$vuetify.breakpoint.mdAndUp ? 174 : '100%'"/>
          <NewBaseButton :button-text="'Create'" @click="sendVoucherDetail"  :color="color"
                         :width="$vuetify.breakpoint.mdAndUp ? 174 : '100%'"/>
        </div>
      </div>

      <div v-if="section === 'edit-hiring-route'" class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 484px; min-width: 512px">
        <p class="header" :style="{color: color}">Edit Leasing Route</p>
        <div class="tw-mb-4 tw-w-full"> <label class="tw-flex ">
          <icons name="location-icon" class="tw-mr-3" :color="color"/>
          Departure City
        </label>
        </div>

        <v-combobox solo hide-details :items="citiesData" :item-text="getItemText" v-model="departureCity" class="tw-w-full">
          <template #append>
            <icons name="arrow-down" :color="color" />
          </template>
        </v-combobox>

        <div class="tw-mb-4 tw-w-full">
          <label class="tw-flex">
            <icons name="destination-icon" class="tw-mr-3" :color="color"/>
            Destination City
          </label>
        </div>
        <v-combobox solo hide-details :items="citiesData" :item-text="getItemText" v-model="destinationCity" class="tw-w-full">
          <template #append>
            <icons name="arrow-down" />
          </template>
        </v-combobox>

        <div class="tw-flex">
          <NewBaseButton :button-text="'Cancel'" :is-outlined="true" outlined="outlined" class="tw-mr-7" :color="color"/>
          <NewBaseButton :button-text="'Add Route'" @click="createNewRoute" :loading="addRouteLoading" :color="color"/>
        </div>

      </div>

      <div v-if="section === 'custom-address'" class=" tw-flex card tw-p-3 lg:tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px">
        <CustomAddress @handleAddress="$emit('handleAddress', address)" @close="$emit('close')" :address="address" />
      </div>


        <div v-if="section === 'transfer-vehicle'"
             class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
             style="min-height: 384px">
            <p class="title tw-pt-5 " :style="{color:color}">Transfer Fleet</p>
            <label class="desc tw-pb-5 tw-mt-5"  :style="{color:color}"
                   v-if="fleetToTransfer && fleetToTransfer.vehicleRegNumber">
                Transfer Fleet: {{fleetToTransfer.vehicleRegNumber}} to another terminal</label>
         <div class="tw-w-full ">
             <v-combobox placeholder="Mile2 Terminal4 Mile 2 Lagos" solo class="text-capitalize"
                         append-icon="mdi-chevron-down"
                         hide-details :color="color" :items="terminals"
                         :item-text="getItemText" v-model="receivingTerminal">
                 <template v-slot:item="{ item }">
                      <span
                       class="text-capitalize">{{ item.terminalName }}
                        {{ item.address.city }},
                        {{ item.address.state }} </span
                      >
                 </template>
             </v-combobox>
         </div>

            <div class="tw-flex tw-w-full tw-justify-around tw-items-center action-btn-div tw-pt-10">
                <v-btn
                        text
                        class="first-btn"
                        @click="$emit('close')"
                        :style="{ color: color, border: '1px solid' + color,}">Close</v-btn>
                <v-btn
                        text
                        class="second-btn"
                        @click="$emit('transferFleet',receivingTerminal)"
                        :style="{ background: color, color: 'white' }"
                        :loading="loading">
                    Transfer Fleet
                </v-btn>
            </div>
            </div>

      <div v-if="section === 'update-trip-amount'"
           class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px">
        <p class="title tw-pt-5 " :style="{color:color}">Update Trip Amount</p>
<!--        <label class="desc tw-pb-5 tw-mt-5"  :style="{color:color}"-->
<!--               v-if="fleetToTransfer && fleetToTransfer.vehicleRegNumber">-->
<!--          Transfer Fleet: {{fleetToTransfer.vehicleRegNumber}} to another terminal</label>-->
        <div class="tw-w-full ">
          <v-text-field placeholder="30,000" solo class="text-capitalize"
                      append-icon="mdi-cash" type="Number"
                      hide-details :color="color"  v-model="tripData.tripAmount">
          </v-text-field>
        </div>

        <div class="tw-flex tw-w-full tw-justify-around tw-items-center action-btn-div tw-pt-10">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{ color: color, border: '1px solid' + color,}">Close</v-btn>
          <v-btn
              text
              class="second-btn"
              @click="$emit('updateTripAmount',tripData)"
              :style="{ background: color, color: 'white' }"
              :loading="loading">
            Update Amount
          </v-btn>
        </div>
      </div>

    </div>
  </v-dialog>
</template>

<script>
import check from "../../assets/check_one.svg";
import Icons from "./Icons";
import {createNewHiringRoute, getTransportCompanyTerminals} from "@/services/api/APIService";
import dayjs from "dayjs";
import {mapGetters} from "vuex";
import NewBaseButton from "@/components/reuseables/NewBaseButton.vue";
import fullcitiesdetails from "@/assets/fullcitiesdetails.json"
import Loader from "@/components/reuseables/Loader.vue";
import CustomAddress from "@/components/reuseables/CustomAddress.vue";
export default {
  name: "Modal",
  components: {CustomAddress, Loader, NewBaseButton, Icons },
  props: {
    dialog: Boolean,
    icon: {
      type: String,
      default: "check",
    },
    title: {
      type: String,
      default: "Title",
    },
    description: {
      type: String,
      default: "",
    },
    additionalText: [String],
    userName: [String],
    bottomText: [String],
    link: {
      type: String,
      required: false,
      default: "Dashboard",
    },
    downloadUrl: [String],
    color: {
      type: [String],
      default: "#004AAD",
    },
    section:{
      type: String,
      default : "main"
    },
    loading:{
      type: Boolean,
      default: false
    },
    fleetToTransfer:{},
    discountDetail:{},
    voucherDetail:{},
  },
  data() {
    return {
      check,
      reason:null,
      terminals:[],
      receivingTerminal:null,
      citiesData : fullcitiesdetails,
      departureCity: {
        city: '',
        state: '',
        country: ''
      },
      destinationCity: {
        city: '',
        state: '',
        country: ''
      },
      addRouteLoading : false,
      address:{},
      tripData:{},
      voucherDateMenu:null,
      voucherMenu : false,
      minDate : new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substr(0, 10),
      voucherTypes:["FIXED","PERCENTAGE"]
    };
  },
    // computed:{
    //   ...mapGetters("companyProfile",["companyData"])
    // },

  computed: {
    ...mapGetters("leasingComponent", ["hiringRouteData"]),
  },
  methods:{
    sendDiscountDetail(){
      if(this.discountDetail.name && this.discountDetail.unit && this.discountDetail.unitAmount && this.discountDetail.type) {
        this.$emit("discountDetail", this.discountDetail);
        this.$emit("close");
      }
      else {
        this.$displaySnackbar({message:'Incomplete Discount Detail', success : false});
      }
    },
    clearDiscountDetail(){
      this.$emit("discountDetail", {});
      this.$emit("close");
    },

    clearVoucherDetail(){
      this.$emit("voucherDetail", {});
      this.$emit("close");
    },
    sendVoucherDetail(){
      if(this.voucherDetail.name && this.voucherDetail.unit && this.voucherDetail.amount && this.voucherDetail.expirationDate) {
        this.$emit("voucherDetail", this.voucherDetail);
        this.$emit("close");
      }
      else {
        this.$displaySnackbar({message:'Incomplete Vocher Detail', success : false});
      }
    },
    async createNewRoute(){
      this.addRouteLoading = true
      if (!this.departureCity|| !this.destinationCity) {
        this.$displaySnackbar({
          message: "Please fill in all required fields",
          success: false,
        });
        this.addRouteLoading = false
        return;
      }
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.departure = this.departureCity
      data.destination = this.destinationCity
      await createNewHiringRoute(data).then(() =>{
        this.addRouteLoading = false
        this.$displaySnackbar({
          message: "Route created successfully",
          success: true
        })
        console.log(data)
        this.$emit('close')

      }). finally(()=>{
        this.addRouteLoading = false;
      })
    },

    async created(){
      // let companyData = JSON.parse(sessionStorage.getItem("companyData"));
      // this.companyData = companyData;
      await this.createNewRoute()
      console.log("Component created")
    },


    handleCancelTrip(){
     if (!this.reason){
       this.$displaySnackbar({
         message: "Reason for cancelling cannot be empty",
         success: false,
       });
     }
     else {
       this.$emit('cancelTrip',this.reason)
     }
    },
      async getAllTerminals() {
          await getTransportCompanyTerminals(this.companyData.id)
              .then((res) => {
                  this.terminals = res.data;
                  this.terminals.sort(function (a, b){
                      return  dayjs(b.created).toDate() - dayjs(a.created).toDate()
                  })
                  this.loading = false;
              })
              .catch((err) => {
                  console.log(err)
              });
      },
      getItemText(item){
        if (item && item.address) {
          return  this.toSentenceCase(item.terminalName) + " " + this.toSentenceCase(item.address.city) +", "+ this.toSentenceCase(item.address.state)
        }
        else {
          return ""
        }
      },
      toSentenceCase(text){
          text = text.toLowerCase()
          return text.replace(text.charAt(0),text.charAt(0).toUpperCase())
      },
  },
    async created() {
      let companyData = JSON.parse(sessionStorage.getItem("companyData"));
      this.companyData = companyData;
      await this.getAllTerminals()
    }

};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  box-shadow: none !important;
  width: 80% !important;
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}
.card {
  width: 97%;
  background-color: white;
  border-radius: 20px;

}
.title {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 36px !important;
  margin-bottom: 0 !important;
  line-height: 44px;
  text-align: center;
  color: #004aad;
}

.cancel-title {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  margin-bottom: 0 !important;
  line-height: 24px;
  text-align: left;
}
.desc {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #333333;
}

.bottomtext {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #004aad;
  text-decoration: none;
}

.second-btn{
  text-transform: none;
}
.first-btn{
  text-transform: none;
}
::v-deep .v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
  text-transform: capitalize;
}
.header{
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #004AAD;
}
.api-loading{
  width: 50px;
  height: 50px;
  color: #00C48C !important;
}
</style>
