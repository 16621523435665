import {isTokenExpired} from "./AuthApiService";
import router from "@/router";


export default async function authHeader()  {
    let user = JSON.parse(sessionStorage.getItem('userToken'));
    if (typeof user === 'object' && user !== undefined && user !== null) {
        let hasExpired = isTokenExpired(user)

        if (hasExpired) {
            await router.push('/login')
          //  await reAuthorize().then(()=>{
          //     user = JSON.parse(sessionStorage.getItem('userToken'));
          //     return {Authorization: `Bearer ${user.access_token}`};
          // })
        }
        else {
            return {Authorization: `Bearer ${user.access_token}`};
        }
    }
    else if (user){
        let hasExpired = isTokenExpired(user)
        if (hasExpired) {
            await router.push('/login')
            // await reAuthorize().then(()=>{
            //     user = JSON.parse(sessionStorage.getItem('userToken'));
            //     return {Authorization: `Bearer ${user}`};
            // })
        }
        else {

            return {Authorization: `Bearer ${user}`}
        }
    }
    else {
        return {};
    }


}


